export type SoulBoundAuthority = {
    version: "0.1.0";
    name: "soul_bound_authority";
    instructions: [
      {
        name: "createSbaUser";
        accounts: [
          {
            name: "sba";
            isMut: true;
            isSigner: false;
          },
          {
            name: "authority";
            isMut: false;
            isSigner: true;
          },
          {
            name: "payer";
            isMut: true;
            isSigner: true;
          },
          {
            name: "systemProgram";
            isMut: false;
            isSigner: false;
          },
          {
            name: "tokenProgram";
            isMut: false;
            isSigner: false;
          },
          {
            name: "rent";
            isMut: false;
            isSigner: false;
          }
        ];
        args: [];
      },
      {
        name: "acceptDelegate";
        accounts: [
          {
            name: "sba";
            isMut: false;
            isSigner: false;
          },
          {
            name: "authority";
            isMut: false;
            isSigner: true;
          },
          {
            name: "delegate";
            isMut: false;
            isSigner: false;
          }
        ];
        args: [];
      },
      {
        name: "revokeDelegate";
        accounts: [
          {
            name: "sba";
            isMut: false;
            isSigner: false;
          },
          {
            name: "authority";
            isMut: false;
            isSigner: true;
          }
        ];
        args: [];
      },
      {
        name: "executeTxScopedUserNftProgram";
        accounts: [
          {
            name: "sbaUser";
            isMut: false;
            isSigner: false;
          },
          {
            name: "nftToken";
            isMut: false;
            isSigner: false;
          },
          {
            name: "nftMint";
            isMut: false;
            isSigner: false;
          },
          {
            name: "authority";
            isMut: false;
            isSigner: false;
          },
          {
            name: "delegate";
            isMut: false;
            isSigner: false;
          },
          {
            name: "authorityOrDelegate";
            isMut: false;
            isSigner: true;
          },
          {
            name: "scopedAuthority";
            isMut: false;
            isSigner: false;
          },
          {
            name: "program";
            isMut: false;
            isSigner: false;
          }
        ];
        args: [
          {
            name: "data";
            type: "bytes";
          }
        ];
      }
    ];
    accounts: [
      {
        name: "soulBoundAuthorityUser";
        type: {
          kind: "struct";
          fields: [
            {
              name: "authority";
              type: "publicKey";
            },
            {
              name: "delegate";
              type: "publicKey";
            },
            {
              name: "bump";
              type: "u8";
            }
          ];
        };
      }
    ];
    errors: [
      {
        code: 6000;
        name: "Todo";
      }
    ];
  };
  
  export const IDL: SoulBoundAuthority = {
    version: "0.1.0",
    name: "soul_bound_authority",
    instructions: [
      {
        name: "createSbaUser",
        accounts: [
          {
            name: "sba",
            isMut: true,
            isSigner: false,
          },
          {
            name: "authority",
            isMut: false,
            isSigner: true,
          },
          {
            name: "payer",
            isMut: true,
            isSigner: true,
          },
          {
            name: "systemProgram",
            isMut: false,
            isSigner: false,
          },
          {
            name: "tokenProgram",
            isMut: false,
            isSigner: false,
          },
          {
            name: "rent",
            isMut: false,
            isSigner: false,
          },
        ],
        args: [],
      },
      {
        name: "acceptDelegate",
        accounts: [
          {
            name: "sba",
            isMut: false,
            isSigner: false,
          },
          {
            name: "authority",
            isMut: false,
            isSigner: true,
          },
          {
            name: "delegate",
            isMut: false,
            isSigner: false,
          },
        ],
        args: [],
      },
      {
        name: "revokeDelegate",
        accounts: [
          {
            name: "sba",
            isMut: false,
            isSigner: false,
          },
          {
            name: "authority",
            isMut: false,
            isSigner: true,
          },
        ],
        args: [],
      },
      {
        name: "executeTxScopedUserNftProgram",
        accounts: [
          {
            name: "sbaUser",
            isMut: false,
            isSigner: false,
          },
          {
            name: "nftToken",
            isMut: false,
            isSigner: false,
          },
          {
            name: "nftMint",
            isMut: false,
            isSigner: false,
          },
          {
            name: "authority",
            isMut: false,
            isSigner: false,
          },
          {
            name: "delegate",
            isMut: false,
            isSigner: false,
          },
          {
            name: "authorityOrDelegate",
            isMut: false,
            isSigner: true,
          },
          {
            name: "scopedAuthority",
            isMut: false,
            isSigner: false,
          },
          {
            name: "program",
            isMut: false,
            isSigner: false,
          },
        ],
        args: [
          {
            name: "data",
            type: "bytes",
          },
        ],
      },
    ],
    accounts: [
      {
        name: "soulBoundAuthorityUser",
        type: {
          kind: "struct",
          fields: [
            {
              name: "authority",
              type: "publicKey",
            },
            {
              name: "delegate",
              type: "publicKey",
            },
            {
              name: "bump",
              type: "u8",
            },
          ],
        },
      },
    ],
    errors: [
      {
        code: 6000,
        name: "Todo",
      },
    ],
  };